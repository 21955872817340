<!--
File: UserRoles.vue
Description: show list of roles assigned to users.
-->
<template>
  <md-card v-if="eligible">
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <md-input type="search" class="mb-3" clearable style="width: 200px"
              :placeholder="$t('label.search_records')" v-model="searchQuery" />
          </md-field>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button v-if="isBtnAllowed('AddButton')" class="md-success" @click="addUserRole">
            {{ $t('buttons.add') }}
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-show="showSpinner" :md-diameter="100" :md-stroke="10" md-mode="indeterminate" />
      <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
        md-fixed-header :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort">
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.search_again')" />
        <md-table-row slot="md-table-row" slot-scope="{item}"
          :class="{ blinking: item.user_role_id === highlightedRow }"
          :id="item.user_role_id === highlightedRow ? 'highlightedRow' : null">
          <md-table-cell :md-label="$t('stdCols.name')" md-sort-by="user_full_name">
            {{ item.user_full_name }}
          </md-table-cell>
          <md-table-cell :md-label="$t('users.email')" md-sort-by="email">
            {{ item.email }}
          </md-table-cell>
          <md-table-cell :md-label="$t('users.role')" md-sort-by="role_key">
            {{ item.role_key }}
          </md-table-cell>
          <md-table-cell :md-label="$t('stdCols.description')" md-sort-by="role_description">
            {{ item.role_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('tables.actions')">
            <div>
              <md-button v-if="isBtnAllowed('DeleteButton')" class="md-just-icon md-danger md-simple"
                :title="$t('buttons.delete')" @click.stop.prevent="deleteItem(item.user_role_id, item.user_full_name)">
                <md-icon>delete</md-icon>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>

    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>
  </md-card>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { Pagination } from '@/components'
import { onClose } from '@/mixins/onCloseMixin'
import { savedMessage } from '@/mixins/messagesMixin'
import permissions from "@/mixins/permissionsMixin"
import listView from '@/mixins/listViewMixin'
import Fuse from 'fuse.js'

export default {
  name: 'user-roles-list',
  mixins: [permissions, listView],

  data() {
    return {
      formName: 'UserRoles',
      eligible: false,

      showSpinner: true,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['user_full_name', 'email', 'role_key', 'role_description'],
      currentSort: 'user_full_name',
      currentSortOrder: 'asc',
      fuseSearch: null,
    }
  },

  components: {
    Pagination
  },

  async mounted() {
    // Check if we are eligible to view the form
    this.eligible = await this.checkIfScreenAllowed()
    if (!this.eligible) {
      this.onClose()
      return
    };

    this.toggleSpinner(true)
    const history = await this.loadHistory()
    if (history.form === 'usersRoles' && history.use) {
      this.currentSort = history.data.currentSort
      this.currentSortOrder = history.data.currentSortOrder
      this.pagination.perPage = history.data.perPage
      this.$nextTick(() => {
        this.searchQuery = history.data.searchQuery
        this.pagination.currentPage = history.data.currentPage
      })
      this.clearHistory()
    }
    await this.getRolesDropdown()
    await this.loadUsersList({})
    await this.reloadData()
  },

  methods: {
    ...mapActions({
      loadList: 'ReferenceData/LOAD_USER_ROLE_LIST',
      getRolesDropdown: 'GET_ROLES_DROPDOWN',
      loadUsersList: 'ReferenceData/LOAD_USER_LIST',
      addRole: 'ReferenceData/ADD_NEW_USER_ROLE',
      delete: 'ReferenceData/DELETE_USER_ROLE',
    }),

    onClose,

    toggleSpinner(state) {
      this.showSpinner = state
    },

    async reloadData() {
      this.toggleSpinner(true)
      await this.loadList({})

      // Fuse search initialization.
      this.fuseSearch = new Fuse(this.tableData, { keys: this.propsToSearch, threshold: 0.3 })
      this.toggleSpinner(false)
    },

    async addUserRole() {
      const { value } = await this.doubledDropdownInput(
        this.$t('label.add_user_roles'),
        this.$t('label.select_user'),
        this.usersDropdownList,
        this.$t('label.select_role'),
        this.rolesDropdownList
      );
      if (!value || value == []) { //User cancelled input
        return
      }
      this.toggleSpinner(true)
      const newRole = {
        "fk_user": value[0],
        "fk_role": value[1]
      }

      let errDesc = ''
      let newUserRoleId;
      try {
        const res = await this.addRole(newRole);
        newUserRoleId = res?.user_role_id;
      } catch (err) {
        errDesc = err.message || this.$t('messages.unknown_error')
      }
      this.toggleSpinner(false)
      const userName = this.usersDropdownList.find(item => item.id == value[0])?.description
      await savedMessage(errDesc, this.$t('messages.role_assigned'), userName)
      this.highlightRow(newUserRoleId)
    },

    save_history() {
      const hist = {
        form: 'usersRoles',
        data: {
          searchQuery: this.searchQuery,
          currentSort: this.currentSort,
          currentSortOrder: this.currentSortOrder,
          perPage: this.pagination.perPage,
          currentPage: this.pagination.currentPage,
        }
      }
      this.saveHistory(hist)
    },
  },

  computed: {
    ...mapState({
      userRolesList: (state) => state.ReferenceData.user_role_list,
      usersList: (state) => state.ReferenceData.user_list,
      rolesDropdownList: (state) => state.Dropdowns.roles,
      highlightedRow: (state) => state.History.row
    }),
    ...mapGetters([]),

    usersDropdownList() {
      return this.usersList.map(item => ({
        id: item.id,
        description: `${item.first_name} ${item.last_name}`
      })).sort((a, b) => a.description.localeCompare(b.description, undefined, { sensitivity: 'base' }))
    },

    tableData() {
      return this.customSort(this.userRolesList, 'user_full_name')
    },
    userRolesCount() {
      return this.userRolesList.length
    },
  },

  watch: {
    async highlightedRow(newVal) {
      if (newVal) {
        await this.reloadData();
        // calculate - which page edited item belongs to
        const ind = this.searchedData.findIndex(item => item.user_role_id == newVal)
        this.pagination.currentPage = Math.ceil(ind / this.pagination.perPage) || 1
        this.scrollToHighlightedRow();
        this.clearHistory();
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-card {
  margin: 0px 0;
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}

.blinking {
  animation: blink-animation 1s linear infinite;
}

@keyframes blink-animation {
  50% {
    background-color: pink;
  }
}
</style>